import { Stack } from '@mui/material'
import {
    CoreTypography,
    LeafBackgroundIcon,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import { FormattedMessage } from 'react-intl'

const NotificationsEmpty: React.FC = () => {
    const { palette } = useTheme()
    return (
        <Stack
            pb={2}
            gap={1}
            alignItems="center"
            justifyContent="center"
            data-testid="notifications-empty"
        >
            <LeafBackgroundIcon
                color="secondary"
                icon={<LeafIcon icon="party-horn" fontSize="small" />}
            />

            <CoreTypography>
                <FormattedMessage
                    defaultMessage="You're all caught up!"
                    description="caught up description"
                />
            </CoreTypography>
        </Stack>
    )
}

export default NotificationsEmpty
